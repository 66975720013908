import { LoadingButton, Typography } from "@samacare/design";
import styled from "styled-components";

export enum FaxFormFilters {
  recommended = "Recommended",
  recentlyUsed = "Recently Used",
  favorite = "Favorites",
}

interface FaxFormFilterProps {
  amount: { [K in keyof typeof FaxFormFilters]: number };
  filter: keyof typeof FaxFormFilters;
  onChange: (filter: keyof typeof FaxFormFilters) => void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
  gap: 8px;
`;

const Title = styled(Typography)`
  font-size: 0.8rem;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  margin-right: 16px;
`;

const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 4px;
  font-weight: 400;
  color: #546e7a;
`;

type FilterTypeProps = {
  active: boolean;
};

const FilterItem = styled(LoadingButton)`
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 4px;
  border-radius: 100px;
  border: 1px solid #90a4ae;
  background-color: ${({ active }: FilterTypeProps) =>
    active ? "#CFD8DC" : "#eceff1"};
  color: #546e7a;
  font-weight: ${({ active }: FilterTypeProps) => (active ? 600 : 400)};
  font-size: 13px;
  box-shadow: none;

  &:not(:disabled):hover {
    background-color: ${({ active }: FilterTypeProps) =>
      active ? "#CFD8DC" : "#5151EE"};
    color: ${({ active }: FilterTypeProps) => (active ? "#546e7a" : "#fff")};
    font-weight: 600;
  }

  &:disabled {
    border: 1px solid #00000061;
    background-color: transparent;
    color: #00000061;

    ${Badge} {
      background-color: #0000001f;
      color: #00000061;
    }
  }
`;

const FaxFormFilter = ({ filter, amount, onChange }: FaxFormFilterProps) => {
  const handleChange = (newFilter: keyof typeof FaxFormFilters) => {
    if (newFilter !== filter) {
      onChange(newFilter);
    }
  };
  return (
    <Container>
      <Title variant="body2">Filter by</Title>
      {Object.keys(FaxFormFilters).map((filterItem) => (
        <FilterItem
          active={(filterItem as keyof typeof FaxFormFilters) === filter}
          key={filterItem}
          disabled={Boolean(!amount[filterItem as keyof typeof FaxFormFilters])}
          onClick={() =>
            handleChange(filterItem as keyof typeof FaxFormFilters)
          }
        >
          <Badge>
            {amount[filterItem as keyof typeof FaxFormFilters] || "-"}
          </Badge>
          {FaxFormFilters[filterItem as keyof typeof FaxFormFilters]}
        </FilterItem>
      ))}
    </Container>
  );
};

export default FaxFormFilter;

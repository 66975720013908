import { PatientBlock } from "@samacare/form";
import { EnrollmentSection } from "../../Enrollment/EnrollmentSection";
import { BenefitsCheckOptionsField } from "./BenefitsCheckOptionsField";
import { Stack } from "@samacare/design/core";
import { FullBvNotificationAlert } from "./FullBvNotificationAlert";
import { LiteBvNotificationAlert } from "./LiteBvNotificationAlert";

export const BenefitsVerificationServiceAndPatientBlock = ({
  isFullBv,
  children,
  patientId,
  drugOptionId,
}: {
  isFullBv: boolean;
  children?: React.ReactNode;
  patientId: string;
  drugOptionId: string;
}) => {
  return (
    <>
      <EnrollmentSection title="Patient">
        <PatientBlock required disabled />
      </EnrollmentSection>
      <EnrollmentSection title="Service Selection">
        <Stack spacing={2} direction="column">
          <Stack spacing={0.5} direction="column">
            <BenefitsCheckOptionsField
              typeFieldName="benefitsCheckType"
              drugOptionIdFieldName="DrugOptionId"
              disabled
            />
            {isFullBv && (
              <FullBvNotificationAlert
                patientId={patientId}
                drugOptionId={drugOptionId}
              />
            )}
            {!isFullBv && <LiteBvNotificationAlert />}
          </Stack>
          {children}
        </Stack>
      </EnrollmentSection>
    </>
  );
};

import * as React from "react";
import { useState } from "react";
import { useAlert, withAlert } from "react-alert";
import { compose } from "recompose";
import styled from "styled-components";
import moment from "moment";

import SimplifiedModal from "@@components/SimplifiedModal";
import PdfViewer from "@@components/PdfViewer";

import { withApollo } from "@apollo/client/react/hoc";

import { Paper, Typography } from "@samacare/design/core";
import InfoOutlined from "@samacare/design/core/icons/InfoOutlined";

import PhoneNumberSelector from "./PhoneNumberSelector";
import { withCreateFormNumber } from "../../graphql/FormNumber";
import { fetchForbiddenFormNumbers } from "../Support/SupportFormsNumbers/routes/formsNumbersUtils";
import { isValidE164PhoneNumber } from "../../util/phoneUtils";

const AuthFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledAuthFormDetails = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 143%;
  gap: 5px;
`;

const StyledTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;
`;

const StyledTag = styled.div`
  background-color: #eceff1;
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 100px;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.38);
  gap: 8px;
  align-items: center;
`;

const FaxNumberModal = ({
  onClose,
  authorizationForm,
  authorizationId,
  institutionId,
  insuranceCompanyId,
  createFormNumber,
  client,
  open,
  onSubmit,
}) => {
  const [number, setNumber] = useState({
    number: "",
    description: "",
    id: null,
    numberError: "",
    descriptionError: "",
  });
  const [selected, setSelected] = useState(null);
  const alert = useAlert();

  const onSelect = (id) => {
    setSelected(selected === id ? null : id);
  };

  const validateNumber = () => {
    if (number?.number && !isValidE164PhoneNumber(number?.number)) {
      setNumber({
        ...number,
        numberError: "Fax Number must be in E164 format",
      });
      return false;
    }
    setNumber({ ...number, numberError: "" });
    return true;
  };

  const validateDescription = () => {
    if (number?.number && !number?.description) {
      setNumber({
        ...number,
        descriptionError: "Name a new number to reuse it next time",
      });
      return false;
    }
    setNumber({ ...number, descriptionError: "" });
    return true;
  };

  const setNewNumber = async () => {
    try {
      const blacklisted = await fetchForbiddenFormNumbers({
        client,
        searchNumber: number.number,
      });

      if (blacklisted) {
        alert.error(blacklisted.forbiddenReason);
        return;
      }

      return await createFormNumber({
        variables: {
          number: number.number,
          description: number.description,
          insuranceCompanyId,
        },
      }).then((res) => res?.data?.createFormNumber?.id);
    } catch (e) {
      if (e?.message) alert.error(e.message);
    }
  };

  const onFinish = async () => {
    if (selected) {
      onSubmit({
        formId: authorizationForm.id,
        formNumberId: selected,
      });
    } else {
      const newNumberId = await setNewNumber();
      if (newNumberId) {
        onSubmit({
          formId: authorizationForm.id,
          formNumberId: newNumberId,
        });
      }
    }
  };

  return (
    <SimplifiedModal
      header="Choose fax number"
      onClick={onClose}
      submit={onFinish}
      open={open}
      submitDisabled={
        !selected &&
        (!number?.number || !number?.description || number.numberError)
      }
      submitText="Use Number"
      back={onClose}
      bodyFlexDirection="row"
    >
      <PhoneNumberSelector
        authorizationFormId={authorizationForm?.id}
        authorizationId={authorizationId}
        institutionId={institutionId}
        insuranceCompanyId={insuranceCompanyId}
        setNumber={setNumber}
        number={number}
        onSelect={onSelect}
        selected={selected}
        validateNumber={validateNumber}
        validateDescription={validateDescription}
      />
      <AuthFormContainer>
        <PdfViewer
          fileURL={authorizationForm?.pdfURL}
          width={594}
          height={353}
        />
        <StyledAuthFormDetails>
          <Typography variant="subtitle2">
            {authorizationForm?.title}
          </Typography>
          <Typography variant="body2">
            {authorizationForm?.description}
          </Typography>
          <StyledTagsContainer>
            {authorizationForm?.formFilter?.jCodes?.map((jCode) => (
              <StyledTag key={jCode} variant="body2">
                {jCode}
              </StyledTag>
            ))}
            {authorizationForm?.formFilter?.states?.map((state) => (
              <StyledTag key={state} variant="body2">
                {state}
              </StyledTag>
            ))}
            {authorizationForm?.formFilter?.insuranceTypes?.map(
              (insuranceType) => (
                <StyledTag key={insuranceType} variant="body2">
                  {insuranceType}
                </StyledTag>
              )
            )}
            {authorizationForm?.insuranceCompaniesNames?.map((name) => (
              <StyledTag key={name} variant="body2">
                {name}
              </StyledTag>
            ))}
          </StyledTagsContainer>
          {authorizationForm?.createdAt ? (
            <Typography variant="caption">
              Added {moment(authorizationForm.createdAt).format("ll")} •
              SamaCare
            </Typography>
          ) : (
            ""
          )}
        </StyledAuthFormDetails>
        <StyledInfoContainer>
          <InfoOutlined />
          <Typography variant="caption">
            Tip: Use the fax number listed on the form for best results
          </Typography>
        </StyledInfoContainer>
      </AuthFormContainer>
    </SimplifiedModal>
  );
};

export default compose(withCreateFormNumber)(
  withApollo(withAlert()(FaxNumberModal))
);

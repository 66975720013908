import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { boolean, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAlert } from "react-alert";
import { Stack } from "@samacare/design";
import { Button, DialogContent } from "@samacare/design/core";
import Dialog from "@samacare/design/core/Dialog";
import DialogActions from "@samacare/design/core/DialogActions";
import DialogTitle from "@samacare/design/core/DialogTitle";
import { Portal } from "@samacare/graphql";
import {
  FormProvider,
  useForm,
  CheckboxField,
  TextField,
  Resolver,
  AutocompleteField,
} from "@samacare/form";
import { useCreatePortal } from "../../../hooks/useCreatePortal";
import { useUpdatePortal } from "../../../hooks/useUpdatePortal";
import { useConfig } from "../../../hooks";

const portalSchema = object<Portal>({
  title: string().required("Title is required"),
  loginUrl: string()
    .required("Login Url is required")
    .url("Must be a valid URL"),
  hubKey: string().nullable(),
  isTop: boolean(),
  isLegacy: boolean(),
  supportCanLogin: boolean(),
  requiresPhoneMfa: boolean(),
});

interface UpsertPortalDialogProps {
  portal: Portal | null;
  portalList: Portal[];
  onClose: () => void;
}
export const UpsertPortalDialog: React.FC<UpsertPortalDialogProps> = (
  props
) => {
  const [isEdit, setIsEdit] = useState(false);
  const alert = useAlert();
  const config = useConfig();
  const createPortal = useCreatePortal();
  const updatePortal = useUpdatePortal();
  const methods = useForm<Portal>({
    resolver: yupResolver(portalSchema) as Resolver<Portal, any>,
  });

  const onSave = async (portal: Portal) => {
    try {
      if (isEdit) {
        await updatePortal(portal);
      } else {
        await createPortal(portal);
      }
      alert.success("Success");
      props.onClose();
    } catch (err: unknown) {
      const e = err as Error;
      alert.error(`There was an error creating this portal, ${e.message}`);
    }
  };

  useEffect(() => {
    if (props.portal) {
      methods.reset(props.portal);
      setIsEdit(!!props.portal.id);
    } else {
      methods.reset({
        isLegacy: true,
      });
    }
  }, [props.portal, methods, setIsEdit]);

  const hubOptions = useMemo(
    () =>
      [
        ..._.difference(
          config.CONSTANTS.NON_DEPRECATED_HUB_KEYS,
          props.portalList.map((p) => p.hubKey)
        ),
        props.portal?.hubKey,
      ]
        .filter(Boolean)
        .sort((a, b) =>
          (a as string).toLowerCase().localeCompare((b as string).toLowerCase())
        ) as string[],
    [props.portalList, config, props.portal]
  );

  const title = isEdit ? "Edit Portal" : "Add Portal";

  return (
    <Dialog
      open={!!props.portal}
      onClose={props.onClose}
      scroll="paper"
      maxWidth="lg"
    >
      <DialogTitle>{title}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSave)}>
          <DialogContent sx={{ width: 600 }}>
            <Stack spacing={2}>
              <TextField
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                fullWidth
                label="Title"
                name="title"
              />
              <TextField fullWidth label="Login URL" name="loginUrl" />
              <AutocompleteField<string, false, true, false>
                options={hubOptions}
                filterSelectedOptions
                name="hubKey"
                label="Hub Key"
                value={methods.watch("hubKey") || ""}
              />
              <CheckboxField
                label="Top"
                name="isTop"
                checked={methods.watch("isTop")}
              />
              <CheckboxField
                label="Legacy"
                name="isLegacy"
                checked={methods.watch("isLegacy")}
              />
              <CheckboxField
                label="Support Can Login"
                name="supportCanLogin"
                checked={methods.watch("supportCanLogin")}
              />
              <CheckboxField
                label="Requires Phone MFA"
                name="requiresPhoneMfa"
                checked={methods.watch("requiresPhoneMfa")}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              data-cy="actionCloseUpsertPortalDialog"
              variant="text"
              onClick={() => {
                methods.reset();
                props.onClose();
              }}
            >
              Close
            </Button>
            <Button
              data-cy="actionSavePortal"
              type="submit"
              variant="contained"
              color="primary"
            >
              {isEdit ? "Save" : "Add"}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

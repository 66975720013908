import { useState } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { MdFavorite as FavoriteIcon } from "@react-icons/all-files/md/MdFavorite";
import { MdFavoriteBorder as EmptyFavoriteIcon } from "@react-icons/all-files/md/MdFavoriteBorder";
import { withSetFavorite, withDeleteFavorite } from "../graphql/Favorite";

// Styled component for both icons, differentiating styles through props
const IconStyled = styled(({ component: Component, ...props }) => (
  <Component {...props} />
))`
  color: ${(props) => props.theme.purple};
  margin: ${(props) => (props.isSimplifiedUx ? "0px" : "0px 3px 2px 0px")};
  height: ${(props) => (props.isSimplifiedUx ? "24px" : "20px")};
  width: ${(props) => (props.isSimplifiedUx ? "24px" : "20px")};
  cursor: pointer;
`;

const Favorite = ({
  isFavorite,
  setFavorite,
  deleteFavorite,
  formId,
  numberId,
  isSimplifiedUx,
}) => {
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    const variables = {
      authorizationFormId: formId,
      formNumberId: numberId,
    };
    if (isFavorite) {
      deleteFavorite({ variables });
    } else {
      setFavorite({ variables });
    }
  };

  const onHover = () => setHover(true);
  const onLeave = () => setHover(false);

  return (
    <span
      data-cy={
        isFavorite ? "actionDeleteFavoriteForm" : "actionSetFavoriteForm"
      }
      onClick={handleClick}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <IconStyled
        component={
          isFavorite
            ? hover
              ? EmptyFavoriteIcon
              : FavoriteIcon
            : hover
            ? FavoriteIcon
            : EmptyFavoriteIcon
        }
        isSimplifiedUx={isSimplifiedUx}
      />
    </span>
  );
};

export default compose(withSetFavorite, withDeleteFavorite)(Favorite);

import _ from "lodash";
import { Box, Button, Stack, Typography } from "@samacare/design";
import {
  FormProvider,
  PatientBlock,
  useForm,
  EdiInsuranceBlock,
  ProviderBlock,
  ServiceBlock,
} from "@samacare/form";
import { EnrollmentSection } from "../../Enrollment/EnrollmentSection";
import { PortalAuth } from "../../../types/PortalAuth";
import { useCurrentAccount } from "../../../graphql/Account";
import { useContext, useState } from "react";
import PortalSelectionModal from "@@components/PortalSelectionModal";
import { useFeatureFlag } from "@@hooks/useFeatureFlag";
import { useConfig } from "@@hooks/config";
import { WebExtensionContext } from "app/contexts/webExtension";
import { doCreate } from "../../../util/authUtils";
import { useAlert } from "react-alert";
import { useCreateAuthorization } from "app/graphql/Authorization";
import { AuthorizationStatusEnum, Query } from "@samacare/graphql";
import { useHistory } from "@@hooks/routing";
import { useQuery } from "@apollo/client";
import { getDrugOptionsQuery } from "app/graphql/DrugOptions";
import { LeftRightCenterAll } from "@samacare/component";

export const PortalAuthorizationCreate = () => {
  const config = useConfig();
  const [currentAccount] = useCurrentAccount();
  const methods = useForm<any>({
    defaultValues: {},
  });
  const history = useHistory();
  const alert = useAlert();
  const [createAuthorization] = useCreateAuthorization();
  const { isValid } = methods.formState;

  const [portalSelectionOpen, setPortalSelectionOpen] =
    useState<boolean>(false);
  const [portalAuthFormData, setPortalAuthFormData] =
    useState<PortalAuth | null>(null);

  const enableBlendedPortalWorkflow = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableBlendedPortalWorkflow
  );
  const { isWebExtensionConnected } = useContext(WebExtensionContext);

  const drugOptionsRes = useQuery<Query>(getDrugOptionsQuery, {
    fetchPolicy: "no-cache",
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    const renderingProvider = { ...data.requestingProvider };
    delete renderingProvider.roleCode;
    delete renderingProvider.PrescriberId;

    const drugOptions = drugOptionsRes.data?.getDrugOptions ?? [];

    const portalAuth: PortalAuth = {
      PatientId: data.PatientId,
      patient: data.patient,
      InstitutionId: currentAccount?.InstitutionId?.toString() ?? "1",
      insurance: {
        memberId: data.patient.primaryInsurance.memberId,
        InsuranceCompanyId: +data.patient.primaryInsurance.InsuranceCompanyId,
        relationshipToSubscriber:
          data.patient.primaryInsurance.relationshipToSubscriber,
      },
      requestingProvider: data.requestingProvider,
      renderingProvider,
      HCPCSCodes: Object.keys(data.services).map((key) => {
        const hcpcsCode = data.services[key].hcpcs.code as string;
        const matchedDrugOption = drugOptions.find(
          (drugOption) => drugOption.code === hcpcsCode
        );

        return {
          code: hcpcsCode,
          drugName: matchedDrugOption?.drugName ?? undefined,
        };
      }),
    };

    setPortalAuthFormData(portalAuth);
    setPortalSelectionOpen(true);
  });

  const handleCreate = async ({
    type,
    portal,
    onClickConfigOverride = {},
    portalAuthData,
  }: {
    type: string;
    portal?: { key?: string; insuranceCompanyName: string; isLegacy: boolean };
    onClickConfigOverride?: Record<string, unknown>;
    portalAuthData?: PortalAuth;
  }) => {
    if (portal?.isLegacy) {
      await doCreate({
        type,
        portal,
        account: currentAccount,
        config,
        createAuthorization,
        history,
        alert,
        isBlendedPortalWorkflow: enableBlendedPortalWorkflow,
      });
    } else if (!portal?.isLegacy && portalAuthData) {
      const defaultFields = {
        PatientId: portalAuthData.PatientId ?? undefined,
        status: AuthorizationStatusEnum.Draft,
        InsuranceCompanyId: portalAuthData.insurance.InsuranceCompanyId,
        requestingProvider: portalAuthData.requestingProvider,
        renderingProvider: portalAuthData.renderingProvider,
        [config.DEFAULT_FIELDS.REQUEST_DESCRIPTION.key]:
          portalAuthData.HCPCSCodes?.[0]?.drugName,
        HCPCS_CODES: _.map(
          portalAuthData.HCPCSCodes,
          (codeObj) => codeObj?.code
        ).join(", "),
      };

      await doCreate({
        type,
        portal,
        onClickConfigOverride,
        otherDefaultFields: defaultFields,
        account: currentAccount,
        config,
        patientId: portalAuthData.PatientId as string,
        createAuthorization,
        alert,
        patientMemberId: portalAuthData.insurance.memberId,
        isBlendedPortalWorkflow: enableBlendedPortalWorkflow,
      });
    }
  };

  return (
    <Stack>
      <Stack>
        <Box borderBottom="1px solid" borderColor="rgba(28, 28, 28, 0.1)">
          <Stack direction="row" spacing={1} m={2}>
            <Typography variant="h6">Prior Authorization</Typography>
          </Stack>
        </Box>
        <Box p={2} pb={4}>
          <Typography variant="h5" sx={{ pb: 1 }}>
            Portal Prior Authorization
          </Typography>
          <Typography variant="body1" sx={{ pb: 4 }}>
            Provide the following info for faster and better PAs with CoPilot.
          </Typography>
          <FormProvider {...methods}>
            <EnrollmentSection title="Patient">
              <PatientBlock required />
            </EnrollmentSection>
            <EnrollmentSection title="Insurance">
              <EdiInsuranceBlock required />
            </EnrollmentSection>
            <EnrollmentSection
              title="Servicing Provider"
              help="To add a new provider, please go to Practice Settings > Provider tab."
            >
              <ProviderBlock
                taxIdRequired={true}
                hideProviderAddress={true}
                hideProviderType={true}
                required
              />
            </EnrollmentSection>
            <EnrollmentSection title="Service">
              <ServiceBlock showOnlyHcpcsSelector={true} required />
            </EnrollmentSection>
            <LeftRightCenterAll sx={{ maxWidth: 600 }}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!isValid}
              >
                Continue To Portal
              </Button>
            </LeftRightCenterAll>
          </FormProvider>
        </Box>
      </Stack>
      {portalSelectionOpen && (
        <PortalSelectionModal
          open={portalSelectionOpen}
          institution={portalAuthFormData?.InstitutionId}
          isWebExtensionConnected={isWebExtensionConnected}
          enableBlendedPortalWorkflow={enableBlendedPortalWorkflow}
          portalAuthFormData={portalAuthFormData}
          closeModal={() => {
            setPortalSelectionOpen(false);
          }}
          onSelect={async (
            portal: {
              key?: string;
              insuranceCompanyName: string;
              isLegacy: boolean;
            },
            onClickConfigOverride?: Record<string, unknown>,
            portalAuthData?: PortalAuth
          ) =>
            handleCreate({
              type: config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key,
              portal,
              onClickConfigOverride,
              portalAuthData,
            })
          }
        />
      )}
    </Stack>
  );
};

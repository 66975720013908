import { PortalAuth } from "../types/PortalAuth";

export const sendMsgToBgScript = (
  msg:
    | {
        type: "CognitoMsg";
        data?: { type: string; data?: string | Record<string, any> };
      }
    | { type: "SCAuthSuccessful" }
    | {
        type: "PortalAuthorizationData";
        data: { url: string; data: PortalAuth };
      }
) => {
  try {
    const { WEB_EXTENSION_IDS } = window.CONFIG.CONSTANTS;

    const webExtensionId = (() => {
      if (window.location.href.includes("provider.samacare.com")) {
        return WEB_EXTENSION_IDS.production;
      } else if (window.location.href.includes("app.samacare.com")) {
        return WEB_EXTENSION_IDS.staging;
      }
      return WEB_EXTENSION_IDS.dev;
    })();
    // TODO:
    // - when publishing to the Edge or Firefox store, update the below condition to include the respective browser
    if ((window as any).chrome && (window as any).chrome.runtime) {
      void (window as any).chrome.runtime.sendMessage(webExtensionId, msg);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error in sending message to bgScript", err);
  }
};
